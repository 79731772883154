import { notifyError } from '@components/notifyError'
import { firebaseAuthService } from '@src/services/firebaseAuth.service'
import { useAuth } from '@src/store/auth/authentication.store'
import { pTry } from '@utils/pTry'
import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'

export const useFirebaseUser = (): { initializing: boolean } => {
  const { user, setUserFromFirebaseAuth, setToken } = useAuth()

  const [initializing, setInitializing] = useState(true)

  useEffect(() => {
    const onAuthStateChanged = async (currentUser: User | null) => {
      if (user?.profile || !currentUser) {
        setInitializing(false)
        return
      }

      const [error, token] = await pTry(currentUser.getIdToken(true))

      if (error) {
        setInitializing(false)
        notifyError(error.message)
        return
      }

      if (currentUser && token) {
        setUserFromFirebaseAuth({
          email: currentUser.email ?? '',
          profilePicture: currentUser.photoURL ?? '',
          displayName: currentUser.displayName ?? '',
          token,
          firebaseId: currentUser.uid,
        })

        setToken(token)
        setInitializing(false)
      }
    }

    return firebaseAuthService.getAuth().onAuthStateChanged(onAuthStateChanged)
  }, [user?.firebaseId])

  return {
    initializing,
  }
}
