import { backendHook } from '@api/trpc'
import { useFirebaseUser } from '@src/hooks/useFirebaseUser'
import { useUserProfileAndRoles } from '@src/hooks/useUserProfileAndRoles'
import { useAuth } from '@src/store/auth/authentication.store'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/globals.css'

// No need to SSR internal pages
const NoSSRSearchBarPage = dynamic(
  () => import('../components/SearchBarPage').then((c) => c.SearchBarPage),
  { ssr: false },
)
const NoSSRLogin = dynamic(
  () => import('../components/auth/signIn').then((c) => c.SignIn),
  { ssr: false },
)

const publicRoutes = [
  '/verify-customer',
  '/redo-verify-customer',
  '/schedule',
  '/schedule-success',
] as const

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter()
  const { isAuthenticated } = useAuth()
  useFirebaseUser()
  useUserProfileAndRoles()

  const renderPublicComponent = () => {
    return <NoSSRLogin />
  }

  const isPublicRoute = publicRoutes.some((route) =>
    router.asPath.startsWith(route),
  )

  return (
    <>
      <Head>
        <title>{'Vässla Care'}</title>
      </Head>
      {isPublicRoute ? (
        <Component {...pageProps} key={router.route} />
      ) : isAuthenticated() ? (
        <NoSSRSearchBarPage>
          <Component {...pageProps} key={router.route} />
        </NoSSRSearchBarPage>
      ) : (
        renderPublicComponent()
      )}
      <ToastContainer />
    </>
  )
}

export default backendHook.withTRPC(MyApp)
