import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth'

const firebaseConfig =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? {
        apiKey: 'AIzaSyD0Ebq6vQRdADMs2VNwF-heTUftJ2HgOyE',
        authDomain: 'vass-live.firebaseapp.com',
        projectId: 'vass-live',
        storageBucket: 'vass-live.appspot.com',
        messagingSenderId: '235064228469',
        appId: '1:235064228469:web:4c0f4efaeaef9c0ce7963c',
        measurementId: 'G-589MGDKNC8',
      }
    : {
        apiKey: 'AIzaSyDdb0uUoqYYgtw65iAD7-eB0Qrwa_6-qPg',
        authDomain: 'vass-development.firebaseapp.com',
        projectId: 'vass-development',
        storageBucket: 'vass-development.appspot.com',
        messagingSenderId: '707272285873',
        appId: '1:707272285873:web:d8f2eb817dd50ae1878c90',
        measurementId: 'G-VPV9J5CSDS',
      }

initializeApp(firebaseConfig)
const googleProvider = new GoogleAuthProvider()

class FirebaseAuthService {
  public getAuth = () => {
    return getAuth()
  }

  public signInWithGoogle = async () => {
    return await signInWithPopup(this.getAuth(), googleProvider)
  }

  public signInWithPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const userCredentials = await signInWithEmailAndPassword(
      this.getAuth(),
      email,
      password,
    )
    if (!userCredentials.user.emailVerified) {
      await sendEmailVerification(userCredentials.user)
    }

    return userCredentials
  }

  public signInWithToken = async (token: string) => {
    const userCredentials = await signInWithCustomToken(this.getAuth(), token)
    return userCredentials
  }

  public registerWithEmailAndPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<UserCredential> => {
    const userCredentials = await createUserWithEmailAndPassword(
      this.getAuth(),
      email,
      password,
    )
    await sendEmailVerification(userCredentials.user)
    return userCredentials
  }

  passwordResetEmail = async (email: string) => {
    await sendPasswordResetEmail(this.getAuth(), email)
  }

  logout = async (): Promise<void> => {
    await this.getAuth().signOut()
  }

  getNewToken = async (): Promise<string | undefined> => {
    return await this.getAuth().currentUser?.getIdToken(true)
  }

  isUserLoggedIn = () => {
    return this.getAuth().currentUser
  }
}
export const firebaseAuthService = new FirebaseAuthService()
