import Cookies from 'js-cookie'

export const getToken = (): string | undefined => {
  return Cookies.get('token')

  // If (typeof window !== 'undefined') {
  //   // Perform localStorage action
  //   const storage = JSON.parse(localStorage.getItem('user'))
  //   const token = storage?.state?.user?.token
  //   return token
  // }
}
