import { backendRequest } from '@api/trpc'
import { notifyError } from '@components/notifyError'
import { firebaseAuthService } from '@src/services/firebaseAuth.service'
import { useAuth } from '@src/store/auth/authentication.store'
import { pTry } from '@utils/pTry'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

export const useUserProfileAndRoles = (): void => {
  const [isPulling, setIsPulling] = useState(false)
  const {
    setStoredProfile,
    logOut,
    isAuthenticated,
    user: authUser,
  } = useAuth()
  const [user, loading] = useAuthState(firebaseAuthService.getAuth())
  const trpc = backendRequest

  useEffect(() => {
    const getMe = async () => {
      setIsPulling(true)
      const [error, me] = await pTry(trpc.member.me.query())

      // Logout the user if we can't communicate to the backend
      // TODO: this should not log out if we want offline mode

      if (error) {
        await logOut()
        notifyError(error.name, error.message)
        setIsPulling(false)
        return
      }

      if (me) {
        setIsPulling(false)
        setStoredProfile(me)
      }
    }

    if (!authUser?.profile && isAuthenticated() && !isPulling) {
      void getMe()
    }
  }, [
    user,
    loading,
    authUser?.token,
    authUser?.profile,
    isAuthenticated,
    isPulling,
    trpc.member.me,
    logOut,
    setStoredProfile,
  ])
}
