import { parseJwt } from '@api/trpc'
import { notifyError } from '@components/notifyError'
import { firebaseAuthService } from '@src/services/firebaseAuth.service'
import Cookies from 'js-cookie'
import { create } from 'zustand'
import {
  AuthContextState,
  UserStoreModel,
  UserWithAccess,
} from './authStore.model'

export interface AuthStoreAction {
  setUserFromFirebaseAuth: (user: UserStoreModel) => void
  logOut: () => Promise<void>
  setToken: (token: string) => void
  setStoredProfile: (profile: UserWithAccess) => void
  setAuthRedirect: (route: string) => void
  refreshToken: () => Promise<void>
  isAuthenticated: () => boolean
}

export const useAuth = create<AuthContextState & AuthStoreAction>(
  (set, getState) => ({
    user: undefined,
    async setUserFromFirebaseAuth(user) {
      if (user.token) {
        Cookies.set('token', user.token)
        delete user.token
      }

      set((state) => {
        // If we already pulled the profile from the backend
        // keep it in sync with the user
        if (state.user?.profile) {
          user.profile = state.user.profile
        }

        return {
          user,
        }
      })
    },
    async logOut() {
      Cookies.remove('token')
      await firebaseAuthService.logout()
      set({ user: undefined })
    },
    setToken(token) {
      if (!token) {
        return
      }

      Cookies.set('token', token)

      set((state) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return { user: { ...state.user, email: state.user?.email!, token } }
      })
    },
    setStoredProfile(profile) {
      set((state) => {
        if (state.user) {
          return { user: { ...state.user, profile } }
        }

        return state
      })
    },
    setAuthRedirect(redirect) {
      set({ redirect })
    },
    async refreshToken() {
      const newToken = await firebaseAuthService.getNewToken()

      if (!newToken) {
        notifyError('Could not refresh token')
        return
      }

      getState().setToken(newToken)
    },
    isAuthenticated() {
      const token = Cookies.get('token')
      if (!token) {
        return false
      }

      const decoded = parseJwt(token)
      const current = (Date.now() + 1) / 1000
      const isTokenExpired = decoded.exp < current

      if (isTokenExpired && firebaseAuthService.isUserLoggedIn()) {
        void getState().refreshToken()
      }

      if (isTokenExpired) {
        return false
      }

      return true
    },
  }),
)
