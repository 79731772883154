import { firebaseAuthService } from '@src/services/firebaseAuth.service'
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@vassla/backend/src/router'
import { decode as atob } from 'base-64'
import { getToken } from './getToken'

export function parseJwt(token: string): Record<string, any> {
  const base64Url = token.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
  if (!base64) {
    return {}
  }

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c: any) {
        return '%' + ('00' + c.codePointAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const baseApiUrl = process.env.NEXT_PUBLIC_BASE_API
/**
 * Configuration for the TRPC client
 */
const trpcClientSettings = {
  url: `${baseApiUrl}/trpc`,
  async headers(): Promise<Record<string, any>> {
    const accessToken = getToken()

    if (accessToken) {
      let token = accessToken
      const decoded = parseJwt(accessToken)
      const current = (Date.now() + 1) / 1000
      const isTokenExpired = decoded.exp < current

      if (isTokenExpired && firebaseAuthService.isUserLoggedIn()) {
        const newToken = await firebaseAuthService.getNewToken()
        if (!newToken) {
          throw new Error('Could not refresh user Token')
        }

        // TODO: set the token in Auth Context
        token = newToken
        // Await AsyncStorage.setItem('token', token)
      }

      return {
        authorization: `Bearer ${token}`,
      }
    }

    return {}
  },
}

/**
 * Vanilla new connection each time
 * Can be used outside of React
 */
export const backendRequest = createTRPCProxyClient<AppRouter>({
  links: [httpBatchLink(trpcClientSettings)],
})

/**
 * TRPC/React-Query hook
 *
 * @returns
 */
export const backendHook = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [httpBatchLink(trpcClientSettings)],
    }
  },
  ssr: true,
  unstable_overrides: {
    useMutation: {
      /**
       * This function is called whenever a `.useMutation` succeeds
       **/
      async onSuccess(opts) {
        /**
         * @note that order here matters:
         * The order here allows route changes in `onSuccess` without
         * having a flash of content change whilst redirecting.
         **/
        // Calls the `onSuccess` defined in the `useQuery()`-options:
        await opts.originalFn()
        // Invalidate all queries in the react-query cache:
        await opts.queryClient.invalidateQueries()
      },
    },
  },
})

export type InferBackendOutput = inferRouterOutputs<AppRouter>
export type InferBackendInput = inferRouterInputs<AppRouter>

export type { AppRouter }
