import ReactAnsi from 'react-ansi'
import { toast } from 'react-toastify'

export const notifyError = (title: string, text?: string): void => {
  toast.error(
    <div>
      <div className="pt-2">{title}</div>
      <hr className="mb-4 mt-2 h-px border-0 bg-gray-200 dark:bg-gray-700" />
      {text && (
        <>
          <div className="text-xs">
            <ReactAnsi log={text!} />
          </div>
          <br />
          <br />
        </>
      )}
    </div>,
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    },
  )
}
